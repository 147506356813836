"use client";
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import { Remove24 } from "@bphxd/ds-core-react/lib/icons";
import { Button } from "./button";
import Pluralise from "../utils/pluralise";
import { COMPLETE_SURVEY } from "../api/users/gql";
import { HasSurveyQuery } from "../gql/graphql";

type SuveryBannerProps = Omit<HasSurveyQuery["hasSurvey"][number], "__typename">;

export const SurveyBanner = (props: SuveryBannerProps) => {
  const { surveyId, estimatedTime, linkToSurvey, surveyName } = props;
  const [displayBanner, setDisplayBanner] = useState(
    window.localStorage.getItem(`display-survey-${surveyId}`) === "false" ? false : true,
  );

  useEffect(() => {
    window.localStorage.setItem(`display-survey-${surveyId}`, String(displayBanner));
  }, [displayBanner]);

  const [completeSurvey] = useMutation(COMPLETE_SURVEY, {
    onCompleted() {
      setDisplayBanner(false);
    },
  });

  if (!displayBanner) {
    return null;
  }

  return (
    <div
      data-testid={`survey-banner-${surveyId}`}
      className="d-flex align-items-center justify-content-between bg-info rounded py-3 ps-5 pe-4 w-100 my-6"
    >
      <p className="m-0">
        Do you have {estimatedTime} minute
        {Pluralise(estimatedTime)}? By taking part in our{" "}
        <a target="_blank" href={linkToSurvey} rel="noreferrer">
          {surveyName}
        </a>{" "}
        it will help us to create a better Accelerate for you.
      </p>
      <Button
        name="close-banner"
        data-testid={`close-survey-${surveyId}`}
        onClick={async () => {
          await completeSurvey({
            variables: {
              completeSurvey: { surveyId, hasCompletedSurvey: true },
            },
          });
        }}
        Icon={Remove24}
        level="quartenary"
        theme="darker"
        size="xs"
      />
    </div>
  );
};
