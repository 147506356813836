import { useQuery } from "@apollo/client";

import { USER_HAS_SURVEY } from "../../api/users/gql";

import { Col, Row } from "reactstrap";
import { Bullet16, Right16 } from "@bphxd/ds-core-react/lib/icons";
import { PageSection, PageWithNoPadding } from "../../components/page-template";
import { SurveyBanner } from "../../components/survey-banner";
import { LinkButton } from "../../components/link-button";
import { Link } from "../../components/Link";
import { LocalProductTour } from "../../components/local-product-tour";
import { EventCards } from "../../components/cards/event-cards";
import { globalTour } from "../../intro-js/steps/global";

import { EventDetails, EVENT_INFO } from "./data/event-info";
import { TourName } from "../../telemetry/enums";
import { WHATS_NEW_CARDS_DATA, WhatsNewPreviewCard } from "../../pages/whats-new";
import { AskAxelButton } from "../../components/button";
import { Alert } from "@bphxd/ds-core-react";
import { Slack } from "react-feather";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { useFeature } from "featurehub-react-sdk";

const cards = [
  {
    id: "1",
    title: "Tap into ten years of research",
    description: "Join your colleagues who are boosting their team performance with our industry-proven framework.",
    href: "/best-practices",
    imgSrc: "/images/dora-research-cycle.svg",
    linkName: "discover-the-framework",
    link: "Discover the framework",
  },
  {
    id: "2",
    title: "Unlock your team potential",
    description:
      "For our new assessment, covering 26 capabilities, we are creating documentation, tools, and workshops to help your team perform.",
    href: "/my-products",
    imgSrc: "/images/png/my-products.png",
    linkName: "explore-the-resources",
    link: "Explore the resources",
  },
  {
    id: "3",
    title: "Equip your team for success",
    description: "Our endorsed kit of tools support your teams across all areas of software delivery.",
    href: "/technology/tools",
    bgColor: "#E1F5FD",
    imgSrc: "/images/png/tools.png",
    linkName: "discover-the-tools",
    link: "Discover the tools",
  },
];

const axelCards = [
  {
    id: "1",
    description: "Please explain what A/B Testing is, and how can I adopt it using ADO Pipelines",
  },
  {
    id: "2",
    description: "What is the adoption status of Appian as a technology within BP?",
  },
  {
    id: "3",
    description: "How do I reduce the number of bugs in my application?",
  },
  {
    id: "4",
    description: "How do I get access to Grafana?",
  },
];

export const EMPIRICAL_ALERT_DISMISS_FLAG = "dismiss_empirical_alert";

function AskAxel() {
  return (
    <>
      <Row className="mt-11" style={{ backgroundColor: "#ffffff" }}>
        <Col className="align-self-center" xs={{ size: 5 }}>
          <div className="d-flex flex-column mx-auto x5-me-8">
            <h3 className="mb-0 fw-light">
              Supercharge your product teams with Accelerate's Al- powered <b>Axel!</b>
            </h3>
            <p className="mt-5 mb-0 fw-light">
              <Bullet16 color="green" className="x5-me-2" />
              Experience the power of joint code and document searches through Axel.
            </p>
            <p className="mt-3 mb-0 fw-light">
              <Bullet16 color="green" className="x5-me-2" />
              Find authoritative solutions to technical issues within bp quickly.{" "}
            </p>
            <p className="mt-3 mb-0 fw-light">
              <Bullet16 color="green" className="x5-me-2" />
              Learn from the code that other product teams have written.
            </p>
            <div className="mt-6">
              <AskAxelButton chatPrompt="what is accelerate?" chatName="Ask axel a question" name="ask-accelerate">
                Ask Axel a question
              </AskAxelButton>
            </div>
          </div>
        </Col>
        <Col xs={{ size: 6, offset: 1 }} className="d-flex justify-content-center align-items-center rounded-5">
          <img alt="illustration" src="/images/png/ask-axel-4-3.png" className="img-fluid pt-6" />
        </Col>
      </Row>
      <div className="d-flex flex-column mt-7">
        <h5 className="fw-light">Try some suggested prompts</h5>
        <div className="d-flex gap-5">
          {axelCards.map(({ id, description }) => (
            <div key={id} className="d-flex flex-column x5-px-5 pt-6 bg-secondary rounded-5 w-25">
              <p className="fw-light mb-0 h-100">{description} </p>
              <div className="align-self-end mt-3">
                <AskAxelButton
                  chatPrompt={description}
                  chatName={description}
                  iconOnly
                  name={`ask-axel`}
                  style={{ border: "1px solid #ccc" }}
                  className="bg-white d-flex justify-content-center mb-5"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function YallaCard() {
  return (
    <div className="rounded-6 mt-10 py-12 d-flex" style={{ backgroundColor: "#fff7e0" }}>
      <Col>
        <img alt="illustration" src="/images/png/yalla-laptop.png" className="img-fluid" />
      </Col>
      <Col className="align-self-center x5-mx-8">
        <div className="d-flex flex-column">
          <h2 className="mb-0 fw-light">
            Introducing <b>Yalla,</b> the upcoming bp internal developer platform
          </h2>
          <p className="mt-5 mb-4 fw-light">
            Yalla is here to simplify development, embrace GitOps, and drive automation.
          </p>
          <p className="mt-0 mb-6 fw-light">
            We're working closely with strategic and greenfield projects to make Yalla the future of software delivery.{" "}
          </p>
          <p className="mt-0 mb-6 fw-light">
            Register your interest and follow our progress by joining the Yalla community channel in Slack**.
          </p>
          <div>
            <LinkButton
              name="join-yalla"
              href="https://bp.enterprise.slack.com/archives/C07HKG1ABT7"
              className="primary pe-4"
              level="primary"
              theme="standard"
              size="md"
              hideExternalLinkIcon
              rounded="pill"
              iconPosition="start"
              Icon={() => <Slack fill={"#fff"} />}
            >
              <span className="ps-3">Join the Yalla community in Slack</span>
            </LinkButton>
            <LinkButton
              name="yalla-find-out-more"
              href="/knowledgehub/6YJdWS"
              className="bg-white x5-ms-3"
              level="tertiary"
              theme="standard"
              rounded="pill"
            >
              Find out more
            </LinkButton>
          </div>
          <span className="my-6 fw-light small">
            **You’ll need a bp Slack account. If you don’t have one,{" "}
            <Link
              name="yalla-find-out-guide"
              href="/knowledgehub/C4dugV"
              className="fw-light small"
              hideExternalLinkIcon
            >
              follow our guide to join the bp Slack workspace.
            </Link>
          </span>
        </div>
      </Col>
    </div>
  );
}

export function WelcomePage({ events }: { events?: EventDetails[] }) {
  const areEventCardsEnabled = useFeature("FE_SHOW_EVENTS_ON_HOMEPAGE_ENABLED");
  const hideEmpiricalData = useFeature("FE_HIDE_EMPIRICAL_DATA");

  const [dismissedMessage, setDismissedMessage] = useLocalStorage(EMPIRICAL_ALERT_DISMISS_FLAG);

  const { data: userSurveys } = useQuery(USER_HAS_SURVEY);

  // Display all non answered surveys, sorted by the estimated time to complete in ascending order
  const userHasSurveys = userSurveys?.hasSurvey ? [...userSurveys.hasSurvey] : [];

  const getNewSurveyBanners = () =>
    userHasSurveys
      ?.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())
      ?.slice(-1)
      ?.map((survey) => {
        return <SurveyBanner key={survey.surveyId} {...survey} />;
      });

  return (
    <PageWithNoPadding name="welcome-page" title="Welcome">
      <div style={{ backgroundColor: "#e7f5e6" }}>
        <PageSection>
          {hideEmpiricalData && !dismissedMessage && (
            <Alert color="warning" className="text-center" dismissible onClick={() => setDismissedMessage("true")}>
              <span className="ms-2 fw-light text-center">
                Product empirical metrics have been disabled. Please read{" "}
                {<Link href="/knowledgehub/Yw7fLL">The Accelerate product metrics approach</Link>} and ensure your
                perceived metrics are up-to-date.
              </span>
            </Alert>
          )}
          <div className="position-relative">
            <div className="position-absolute w-100 mt-n6">{getNewSurveyBanners()}</div>
          </div>
          <Row className="py-10">
            <Col xs="5" className="pe-9 pe-xl-10 pe-xxl-11 pb-10" style={{ borderRight: "2px solid #c5e7c1" }}>
              <h1 className="display-3 fw-light">Helping bp teams deliver digital products</h1>
              <p className="lead fw-light mb-5">
                At Accelerate we’re dedicated to supporting product and engineering teams that are eager to enhance
                their performance
              </p>
              <div className="ms-n4">
                <LocalProductTour
                  dataTestId={"welcome-page-product-tour"}
                  steps={globalTour}
                  buttonText="Accelerate product tour"
                  tourName={TourName.Global}
                />
              </div>
            </Col>

            <Col xs="7" className="d-flex flex-wrap ps-9 ps-xl-10 ps-xxl-11">
              <div className="w-100 d-flex align-items-center justify-content-between mb-5">
                <h5 className="fw-light mb-0">What’s new in Accelerate</h5>
                <LinkButton href="/whats-new" className="px-0" size="xs" level="quartenary" name="whats-new-page">
                  See all updates <Right16 className="me-n2" />
                </LinkButton>
              </div>

              {WHATS_NEW_CARDS_DATA.slice(0, 2).map((data, index) => (
                <div key={index} className={`w-50 d-flex ${index === 0 ? "pe-4" : "ps-4"}`}>
                  <WhatsNewPreviewCard {...data} />
                </div>
              ))}
            </Col>
          </Row>
        </PageSection>
      </div>
      {areEventCardsEnabled && <EventCards eventDetails={events ?? EVENT_INFO} />}
      <PageSection>
        <YallaCard />
        <AskAxel />
        <div className="pt-11"></div>
        <Row className="border-top pt-11">
          {cards.map(({ id, title, description, linkName, imgSrc, href, link, bgColor }, index) => (
            <Col className={"d-flex justify-content-center align-items-center"} key={id}>
              <div className="d-flex flex-column">
                <img
                  alt={title}
                  src={imgSrc}
                  className={`img-fluid ${index === 1 ? "mt-5" : "mt-0"} rounded-5`}
                  style={{ backgroundColor: bgColor ?? "" }}
                />
                <h4 className="fw-light mt-6 pb-0 mb-4">{title}</h4>
                <p className="fw-light mb-0">{description}</p>
                <Link name={linkName} href={href} className="fw-light mt-5">
                  {link}
                  <Right16 className="x5-ms-1" />
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </PageSection>
    </PageWithNoPadding>
  );
}
